import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const AddIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M1.72998 10.29L18 10.21"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.80998 2.12012L9.89998 18.3801"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
