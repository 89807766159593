import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const InstagramIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox=" 0 0 32 32" {...props}>
    <g stroke="currentColor" fill="none" strokeWidth="1.5px">
      <rect x=".691" y=".96" width="29.76" height="29.76" rx="2.71" />
      <circle cx="15.748" cy="15.663" r="6.182" />
    </g>
  </Svg>
)
