import {
  CustomersCustomer,
  CustomersDeliveryAddress,
  CustomersContactPreferences,
} from '@mindfulchefuk/api-client'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { Customer } from '@mindfulchefuk/query/Customer/interfaces'
import { transformCustomer } from '@mindfulchefuk/query/Customer/transforms/transformCustomer'

export type UpdateCustomerPayload =
  | {
      type: 'default_delivery_address'
      data: Pick<Customer, 'address'>
    }
  | {
      type: 'personal'
      data: Pick<Customer, 'email' | 'firstName' | 'lastName'>
    }
  | {
      type: 'contact_preferences'
      data: Pick<Customer, 'contactPreferences'>
    }
  | {
      type: 'survey'
      data: Pick<Customer, 'personalisationSurveyState'>
    }

export const updateCustomer = async (
  update: UpdateCustomerPayload,
  customer: Customer
): Promise<Customer> => {
  try {
    const { customerId, email, firstName, lastName } = customer

    const updatedCustomer = new CustomersCustomer({ id: customerId })
    updatedCustomer.isPersisted = true

    if (update.type === 'personal') {
      updatedCustomer.first_name = update.data.firstName
      updatedCustomer.last_name = update.data.lastName
      updatedCustomer.email = update.data.email
    }

    if (update.type === 'survey') {
      updatedCustomer.personalisation_survey_state =
        update.data.personalisationSurveyState
    }

    if (update.type === 'default_delivery_address') {
      // We always create a new instance which will get a new ID
      const { address } = update.data
      updatedCustomer.default_delivery_address = new CustomersDeliveryAddress({
        // `country_code` is not writable
        street_1: address.street1,
        street_2: address.street2,
        street_3: address.street3,
        delivery_instructions: address.deliveryInstructions,
        locality: address.locality,
        postcode: address.postcode,
        recipient_phone: address.recipientPhone,
        recipient_email: email ? email.toLowerCase() : undefined,
        recipient_name: `${firstName} ${lastName}`,
      })
    }

    if (update.type === 'contact_preferences') {
      const { contactPreferences } = update.data
      updatedCustomer.contact_preferences = new CustomersContactPreferences({
        id: customer.contactPreferences.id,
      })

      // We update rather than create a new instance
      updatedCustomer.contact_preferences.isPersisted = true

      updatedCustomer.contact_preferences.post_marketing_opt_in =
        contactPreferences.postMarketingOptIn
      updatedCustomer.contact_preferences.sms_marketing_opt_in =
        contactPreferences.smsMarketingOptIn
      updatedCustomer.contact_preferences.email_marketing_opt_in =
        contactPreferences.emailMarketingOptIn
      updatedCustomer.contact_preferences.phone_marketing_opt_in =
        contactPreferences.phoneMarketingOptIn
    }

    // We pass "with" to the save method only when we're updating a sub-resource
    const saveConfig =
      update.type === 'contact_preferences' ||
      update.type === 'default_delivery_address'
        ? { with: update.type }
        : undefined

    const success = await updatedCustomer.save(saveConfig)

    if (!success) {
      throw new Error('Update failed to persist')
    }

    // The API doesn't always return each sub-resource on PATCH, so we need to merge the existing data with the updated data
    return {
      ...customer,
      ...transformCustomer(updatedCustomer),
    }
  } catch (e) {
    errorHandler(e, { suppress: true })
  }
}
