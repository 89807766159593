import { CheckoutState } from '@mindfulchefuk/features/Checkout/interfaces'
import { createAction } from '@reduxjs/toolkit'

export const setCardIsValid = createAction<boolean>(
  'CHECKOUT_CARD_IS_VALID_SET'
)

export const setPaymentTokenInProgress = createAction<boolean>(
  'CHECKOUT_PAYMENT_TOKEN_IN_PROGRESS_SET'
)
export const setPaymentToken = createAction<string>(
  'CHECKOUT_PAYMENT_TOKEN_SET'
)

export const setCheckoutError = createAction<{
  type: string
  props?: {
    message?: string
  }
}>('CHECKOUT_ERROR_SET')

export const resetCheckout = createAction('CHECKOUT_RESET')

export const setCheckoutSteps = createAction<string[]>('CHECKOUT_STEPS_SET')

export const setCheckoutSubmitting = createAction<boolean>(
  'CHECKOUT_SUBMITTING_SET'
)

export const setCheckoutValue =
  createAction<Partial<CheckoutState>>('CHECKOUT_VALUE_SET')

export const setVoucherError = createAction<{
  type: string
  props?: {
    message?: string
  }
}>('CHECKOUT_VOUCHER_ERROR_SET')

export const unsetVoucherError = createAction('CHECKOUT_VOUCHER_ERROR_UNSET')
