import React, { ReactNode, useEffect, useRef } from 'react'
import Head from 'next/head'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Provider as JotaiProvider } from 'jotai'

// Import core-js, to allow additional polyfills to be included
import 'core-js/stable'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'instantsearch.css/themes/reset.css'
import '@mindfulchefuk/css'

import '@mindfulchefuk/lib/api-client-patch'
import '@mindfulchefuk/utils/setFeatureFlags'

import GlobalContainer from '@mindfulchefuk/containers/GlobalContainer'
import { PageLoader } from '@mindfulchefuk/components/Loader'
import Bugsnag from '@mindfulchefuk/lib/bugsnag'
import Error from '@mindfulchefuk/pages/_error.page'
import { GrowthBookProvider } from '@mindfulchefuk/context/GrowthBookProvider/GrowthBookProvider'

import { NextAppPropsWithLayout } from '@mindfulchefuk/types/NextJS'
import { useReduxStore } from '@mindfulchefuk/hooks/useReduxStore'
import { useDatadogLogging } from '@mindfulchefuk/hooks/useDatadogLogging'
import { useCookieConsent } from '@mindfulchefuk/features/Cookies/hooks/useCookieConsent'

import Script from 'next/script'
import { useAllowedDixaRoutes } from '@mindfulchefuk/features/Dixa/useAllowedDixaRoutes'
import { configureGrowthBook } from '@mindfulchefuk/context/GrowthBookProvider/configureGrowthBook'
import '../styles/globals.css'

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React
) as React.ComponentType<{
  // https://github.com/bugsnag/bugsnag-js/issues/1638 Bugsnag has the wrong types as NextJS is 'not officially supported by Bugsnag'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FallbackComponent: React.ComponentType<any>
  children?: ReactNode
}>
const queryClient = new QueryClient()

const shouldShowPersistGate = (path: string) =>
  path.startsWith('/choose-recipes') ||
  path.startsWith('/checkout') ||
  path.startsWith('/my-account')

const growthbook = configureGrowthBook()

const App = ({ Component, pageProps, router }: NextAppPropsWithLayout) => {
  useDatadogLogging()
  useAllowedDixaRoutes()
  useCookieConsent()
  const { store, persistor } = useReduxStore()
  const previousPath = useRef(router.pathname)
  const getLayout = Component.getLayout || ((page) => page)
  const page = getLayout(<Component {...pageProps} />)

  // Only show the persist gate if first page loaded matches the subset above
  const showPersistGate = useRef(shouldShowPersistGate(router.pathname))

  const content = showPersistGate.current ? (
    <PersistGate loading={<PageLoader />} persistor={persistor}>
      {page}
    </PersistGate>
  ) : (
    page
  )

  // call the page() function required for page targeted customer io in app messaging when the url changes
  useEffect(() => {
    const newPath = router.pathname

    if (previousPath.current !== newPath) {
      const customerIo = window.analytics
      customerIo.page({ url: newPath })
      previousPath.current = newPath
    }
  }, [router.pathname])

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <GlobalContainer>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps?.dehydratedState}>
            <ReactQueryDevtools />
            <Provider store={store}>
              <Head>
                <link
                  rel="apple-touch-icon"
                  type="image/png"
                  sizes="180x180"
                  href="/apple-touch-icon.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32.png"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16.png"
                />
                <meta charSet="utf-8" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <meta
                  name="google-site-verification"
                  content="UFjz4os59EpP5bZaT76MFwe_5WrQ4Qvye6814MP6FXU"
                />
              </Head>
              <Script
                src={`https://cdn.optimizely.com/js/${process.env.NEXT_PUBLIC_OPTIMIZELY_PROJECT_ID}.js`}
              />
              <Script
                id="customer-io-in-app-messages"
                dangerouslySetInnerHTML={{
                  __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdp.customer.io/v1/analytics-js/snippet/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._writeKey=key;analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.15.3"; analytics.load("${process.env.NEXT_PUBLIC_CUSTOMER_IO_IN_APP_MESSAGING_API_KEY}", {"integrations": { "Customer.io In-App Plugin": { siteId: "${process.env.NEXT_PUBLIC_CUSTOMER_IO_IN_APP_MESSAGING_SITE_ID}" } } }); analytics.page(); }}();`,
                }}
              />
              <Script
                id="google-tag-manager"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
                }}
              />

              <JotaiProvider>
                <GrowthBookProvider growthbook={growthbook}>
                  {content}
                </GrowthBookProvider>
              </JotaiProvider>
            </Provider>
          </Hydrate>
        </QueryClientProvider>
      </GlobalContainer>
    </ErrorBoundary>
  )
}

export default App
