import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const PoultryIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 22 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor">
        <path
          d="M0 17.342s.817-8.07 1.29-9.09c.603-1.63 2.126-2.746 3.873-2.84 2.39-.22 3.64-1.44 3.64-3S8.016-.708 7.34.342c-.676 1.05-1.18 1.85-1.18 1.85s-1.09-1.34-1.392-.58c-.198.41-.308.856-.322 1.31 0 0-1.341-.46-1.28.52-.003.392.066.782.2 1.15M9.105 4.952c1.082.095 2.046.717 2.572 1.66.665 1.51 2.4 10.58 2.4 10.58s-1.2-.49-3.61.49-2.834 1.7-3.792 1.23c-.958-.47-2.148-1.48-3.126-1.52-.375-.035-.753-.035-1.129 0"
          transform="translate(-541.000000, -816.000000) translate(540.000000, 264.000000) translate(0.000000, 519.000000) translate(1.941667, 33.000000) translate(3.025000, 0.000000)"
        />
        <path
          d="M5.929 11.252L7.028 13.152 8.137 11.252z"
          transform="translate(-541.000000, -816.000000) translate(540.000000, 264.000000) translate(0.000000, 519.000000) translate(1.941667, 33.000000) translate(3.025000, 0.000000)"
        />
      </g>
    </g>
  </Svg>
)
