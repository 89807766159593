import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const SoilIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 22 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor">
        <path d="M0.783936 13.0319C6.07282 11.483 7.87671 11.4075 11.9756 12.758C12.4573 12.9186 13.09 13.2113 12.92 13.7025C12.75 14.1936 12.3062 14.1652 11.9095 14.1747L8.34894 14.2219" />
        <path d="M0.604492 17.0559C2.1313 16.323 3.85535 16.1075 5.5156 16.442C6.9606 16.7348 8.34894 17.4526 9.8506 17.3015C10.8615 17.1175 11.8251 16.7321 12.6839 16.1681L18.7378 12.7681C17.7934 11.5687 15.3945 11.6726 14.0156 12.4281" />
        <path d="M8.5 10.5009C10.5491 9.60923 12.9062 9.78593 14.7994 10.9731" />
        <path d="M11.7112 9.6983C11.9682 7.45643 13.0486 5.38988 14.7428 3.89941" />
        <path d="M12.5423 6.36446C12.02 6.11024 11.6438 5.62984 11.5221 5.06186C11.4003 4.49388 11.5467 3.90149 11.919 3.45557C13.4112 1.64224 16.0368 1.49113 17.8407 1.56669C17.4431 2.97507 16.9734 4.36208 16.4334 5.72224C16.1594 6.29929 15.6624 6.74003 15.0567 6.94311C14.4511 7.14619 13.7888 7.09413 13.2223 6.79891" />
        <path d="M11.6734 9.32968C11.2974 7.66234 10.3014 6.20039 8.88733 5.24023" />
      </g>
    </g>
  </Svg>
)
