import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const FreezeIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 16 16" {...props}>
    <g stroke="currentColor" fill="none">
      <path d="M1.475 14.783S6.2 8.992 8.092 7.375C9.983 5.758 14.817.508 14.817.508" />
      <path d="M1.608 1.533s4.85 3.984 7.617 6.8a56.305 56.305 0 005.592 4.942" />
      <path d="M10.1 14.783l.733-4.925s1.667-.066 2.409 0c.741.067 1.433.167 1.433.167M9.842.708c-.15.458-.225.936-.225 1.417.063 1.153.191 2.303.383 3.442 0 0 1.867.075 2.55 0 .683-.075 2.1-.417 2.1-.417M.558 5.6c1.081.098 2.17.098 3.25 0 .665-.11 1.318-.283 1.95-.517 0 0-.241-2.45-.308-3A7.5 7.5 0 005.067.708M.833 10.3l4.7-.3s-.15 1.792-.108 2.867c.042 1.075.108 1.991.108 1.991" />
    </g>
  </Svg>
)
