import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import sortOptionsConfig, {
  RecipeSortId,
} from '@mindfulchefuk/config/sortOptionsConfig'
import sortBy from '@mindfulchefuk/utils/sortBy'

export const sortRecipesBySortBy = <TRecipe extends Recipe>(
  recipes: TRecipe[],
  sortOption: RecipeSortId
): TRecipe[] => {
  const asc = sortOption !== 'customerFav'
  const { getValue } = sortOptionsConfig.find(
    (config) => config.id === sortOption
  )
  if (sortOption === 'customerFav') {
    const sortedByOrderRecipes = [...recipes].sort((a, b) => {
      if (a.sortOrder === null) {
        return 1
      }
      if (b.sortOrder === null) {
        return -1
      }
      if (a.sortOrder === b.sortOrder) {
        return 0
      }
      return a.sortOrder < b.sortOrder ? -1 : 1
    })
    return [...sortedByOrderRecipes].sort((a, b) =>
      sortBy(getValue(a), getValue(b), { asc })
    )
  }

  return [...recipes].sort((a, b) => sortBy(getValue(a), getValue(b), { asc }))
}
