import React, { ReactNode } from 'react'
import { Box, BoxProps } from '@mindfulchefuk/design-system/layout/Box'

export const ModalBody = ({
  children,
  ...boxProps
}: BoxProps & { children: ReactNode }) => (
  <Box position="relative" w="100%" p={24} color="aubergine" {...boxProps}>
    {children}
  </Box>
)
