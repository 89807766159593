import React, { ReactElement } from 'react'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { SpaceToken } from '@mindfulchefuk/design-system/system/theme/space'
import { Responsive } from '@mindfulchefuk/design-system/system/types'

export const CheckboxIcon = ({
  checked,
  size = 28,
}: {
  checked: boolean
  size?: Responsive<SpaceToken>
}): ReactElement => (
  <Icon
    type={checked ? 'checkbox-ticked' : 'checkbox'}
    size={size as Responsive<SpaceToken>}
  />
)
