import styled from '@emotion/styled'
import { inputStyles } from '@mindfulchefuk/design-system/Forms/form.styles'
import { TextareaHTMLAttributes } from 'react'

export type TTextArea = TextareaHTMLAttributes<HTMLTextAreaElement>

export const Textarea = styled.textarea`
  ${inputStyles};
  height: 150px;
  resize: none;
`
