import React, { ReactElement } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

export const SectionBackground02 = (): ReactElement => (
  <>
    <Illustration
      type="cross-section-squash-one"
      top={[-32, 40]}
      right={[-144, -128]}
    />
    <Illustration
      type="cross-section-squash-one"
      transform="scale(.72)"
      display={['none', 'block']}
      top={-20}
      right={-80}
      z={0}
    />
    <Illustration
      type="chopped-squash-one"
      bottom={[40, 128]}
      right={[0, -16]}
    />
    <Illustration
      type="chopped-squash-two"
      display={['none', 'block']}
      bottom={48}
      right={24}
    />
    <Illustration
      type="cross-section-squash-one"
      z={0}
      left={-64}
      bottom={-44}
      display={['none', 'block']}
    />
    <Illustration
      type="cross-section-beetroot-one"
      bottom={[-40, 40]}
      left={[-96, -20, -112]}
    />
    <Illustration
      type="chopped-beetroot-one"
      top={[64, 40]}
      left={[-96, -192]}
    />
    <Illustration
      type="chopped-squash-two"
      display={{ base: 'none', md: 'block' }}
      top={64}
      left={40}
    />
  </>
)
