import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const CalendarIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 20" data-testid="calendar-icon" {...props}>
    <g fill="none" stroke="currentColor">
      <path
        transform="translate(1.3417 1)"
        d="M1.679 5.429c-.279-.31-.408-.725-.353-1.137.054-.411.286-.78.635-1.008.723-.428 1.56-.626 2.4-.57l8.411-.316c1.305 0 2.737 0 3.759.742 1.396 1.058 1.505 3.067 1.487 4.813l-.064 6.397c.08.772-.093 1.549-.493 2.216-.69.709-1.642 1.107-2.636 1.104-3.405.41-6.835.567-10.263.47-.868.059-1.73-.16-2.464-.624-.747-.76-1.152-1.788-1.122-2.85-.242-2.503-.242-5.024 0-7.527M2.865 7.365L17.726 6.677M6.386.742c-.238 1.138-.263 2.31-.073 3.456M12.717.742c-.238 1.138-.263 2.31-.072 3.456"
      />
      <path
        transform="translate(6.667 11.595) rotate(-2.26) translate(-5.3253 -10.595)"
        d="m4.084 9.618 2.482 1e-3v1.954l-2.481-1e-3z"
      />
      <path
        transform="translate(10.672 11.439) rotate(-2.26) translate(-9.3302 -10.439)"
        d="m8.089 9.461 2.482 1e-3v1.954h-2.481z"
      />
      <path
        transform="translate(14.693 11.283) rotate(-2.2906) translate(-13.351 -10.283)"
        d="m12.109 9.304 2.484 1e-3v1.956l-2.483-1e-3z"
      />
      <path
        transform="translate(6.8147 15.015) rotate(-2.2906) translate(-5.4731 -14.015)"
        d="m4.231 13.037 2.483 1e-3 1e-3 1.955h-2.483z"
      />
      <path
        transform="translate(10.817 14.851) rotate(-2.26) translate(-9.4749 -13.851)"
        d="m8.234 12.873 2.481 1e-3 1e-3 1.954h-2.482z"
      />
      <path
        transform="translate(14.839 14.688) rotate(-2.2906) translate(-13.498 -13.688)"
        d="m12.256 12.71 2.483 1e-3 1e-3 1.956-2.484-1e-3z"
      />
    </g>
  </Svg>
)
