import React, { ReactElement } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

export const SectionBackground01 = (): ReactElement => (
  <>
    <Illustration
      type="cross-section-artichoke"
      bottom={[-192, -96]}
      left={[-20, -160]}
      transform="scale(2.2) rotate(25deg)"
    />
    <Illustration
      type="chopped-beetroot-one"
      bottom={[-20, 'calc(100% + -120px)']}
      right={[48, 'calc(100% - 120px)']}
      transform="rotate(20deg)"
    />
    <Illustration
      type="chopped-tomato-two"
      bottom={[-112, 'calc(100% - 150px)']}
      right={[0, 'calc(100% + 35px)']}
      transform={['scale(1.2)', 'scale(1.4)']}
    />
    <Illustration
      type="cross-section-cucumber"
      display={['none', 'block']}
      top={64}
      right={-240}
      transform="scale(1.6)"
    />
    <Illustration
      type="cross-section-turnip"
      display={['none', 'block']}
      bottom={6}
      right={-80}
      transform="scale(1.8)"
    />
  </>
)
