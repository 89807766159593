/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import React, { ReactElement } from 'react'
import { PandaButtonVariantProps, pandaButton } from 'styled-system/recipes'

type PandaButtonProps = PandaButtonVariantProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children: React.ReactNode
    'data-testid'?: string
  }
export const PandaButton = (props: PandaButtonProps): ReactElement => {
  const {
    children,
    onClick,
    size,
    variant,
    fullWidth,
    textWrap,
    disabled,
    type,
  } = props

  return (
    <button
      onClick={onClick}
      className={pandaButton({ size, variant, fullWidth, textWrap })}
      disabled={disabled}
      data-testid={props['data-testid']}
      type={type || 'button'}
    >
      {children}
    </button>
  )
}
