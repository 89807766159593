import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const ArrowCurvedIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M18.52 6.11C15.6 4.5 11.86 5.06 9 6.74c-2.86 1.68-5.05 4.34-7 7"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.67 8a22.08 22.08 0 0 0-.37 5.25c-.018.277.06.553.22.78.29.35.85.29 1.31.19l4.7-.94"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
