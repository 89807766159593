import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const TwitterIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 36 30" {...props}>
    <path
      d="M24.35 1a6.926 6.926 0 00-6.928 6.946c-.004.53.056 1.059.18 1.573A19.71 19.71 0 013.315 2.284a6.824 6.824 0 00-.94 3.473 6.905 6.905 0 003.13 5.844 8.149 8.149 0 01-3.149-.78 6.926 6.926 0 005.553 6.802c-.59.161-1.2.24-1.809.236a6.575 6.575 0 01-1.302-.126 6.964 6.964 0 006.476 4.81 13.85 13.85 0 01-8.628 2.967c-.55-.002-1.1-.033-1.646-.09a19.612 19.612 0 0010.69 3.112c3.4.037 6.752-.804 9.73-2.443 2.64-1.501 4.886-3.61 6.55-6.15a21.396 21.396 0 003.13-7.778c.126-.67.217-1.346.27-2.026 0-.326 3.87-6.603 3.618-6.983-.252-.378-5.625.96-5.896.651A6.195 6.195 0 0024.35 1z"
      strokeWidth="1.5px"
      stroke="currentColor"
      fill="none"
    />
  </Svg>
)
