import React, { MouseEventHandler, ReactElement, ReactNode } from 'react'
import { ResetButton, Button, PandaButton } from '../Button'
import { Icon, IconType } from '../Icon'
import { Box, Flex } from '../layout'
import { BoxProps } from '../layout/Box'
import { ColorToken } from '../system'
import { Text } from '../Text'

export type NotificationVariant = 'info' | 'warning' | 'success' | 'error'
export type NotificationType = 'banner' | 'inline'

const typeStyles: Record<NotificationType, BoxProps> = {
  banner: {
    top: 0,
    borderBottomWidth: 2,
    position: 'relative',
  },
  inline: { borderWidth: 1 },
}

const variantStyles: Record<
  NotificationVariant,
  BoxProps & {
    iconColor: ColorToken
    icon?: IconType
  }
> = {
  error: {
    backgroundColor: 'tomato-50',
    borderColor: 'tomato',
    color: 'aubergine-800',
    iconColor: 'tomato',
    icon: 'warning-filled',
  },
  success: {
    backgroundColor: 'broccoli-50',
    borderColor: 'broccoli',
    color: 'aubergine-800',
    iconColor: 'broccoli',
    icon: 'roundel-tick-filled',
  },
  warning: {
    backgroundColor: 'lemon-100',
    borderColor: 'pumpkin-400',
    color: 'aubergine-800',
    iconColor: 'pumpkin-400',
    icon: 'warning-filled',
  },
  info: {
    backgroundColor: 'salt',
    borderColor: 'salt-600',
    color: 'aubergine-800',
    iconColor: 'aubergine',
  },
}

export type TNotificationProps = BoxProps & {
  children?: ReactNode
  type: NotificationType
  /** The colour style of the notification */
  variant: NotificationVariant
  /** Custom icon for info, success & warning variants */
  icon?: IconType
  /** Custom color for the icon */
  iconColor?: ColorToken
  /** Capitalized title that sits next to optional icon */
  title?: string
  /** Called when the notification is dismissed - is also used to show dismiss button if this prop is present */
  onDismiss?: () => void
  /** A unique ID for the notification */
  id?: string
  /** Properties for an optional call-to-action */
  cta?: {
    onClick: MouseEventHandler
    label: string
  }
}

export const Notification = ({
  children,
  variant,
  title,
  icon: propIcon,
  iconColor: propIconColor,
  cta,
  onDismiss,
  id,
  type,
  ...props
}: TNotificationProps): ReactElement => {
  // Get the styles for the type of notification, e.g. 'banner' or 'inline'
  const typeStyle = typeStyles[type]

  // Get the styles and button colours etc. for the variant, e.g. 'success' or 'warning'
  const {
    icon: defaultIcon,
    iconColor: defaultIconColor,
    ...styles
  } = variantStyles[variant]

  const icon = propIcon ?? defaultIcon

  // Set the icon colour if provided
  const iconColor = propIconColor || defaultIconColor

  return (
    <Box
      data-testid={id}
      id={id}
      position="relative"
      p={16}
      {...styles}
      {...typeStyle}
      {...props}
    >
      {/* Mobile only */}
      <Box display={{ sm: 'none' }} textAlign="center">
        {title && (
          <Flex justify="center" align="flex-start">
            <Text
              as="h3"
              variant="body-md-500-caps"
              mb={4}
              flexGrow={1}
              data-testid={`${id}-title`}
            >
              {icon && (
                <Icon
                  color={iconColor}
                  type={icon}
                  size="20px"
                  mr={8}
                  position="relative"
                  top={4}
                />
              )}
              {title}
            </Text>
            {onDismiss && (
              <Flex position="relative" top={4} display="block" pl={8}>
                <ResetButton onClick={onDismiss}>
                  <Icon type="thick-cross" size={20} />
                </ResetButton>
              </Flex>
            )}
          </Flex>
        )}

        <Text as="span" variant="body-md" data-testid={`${id}-text`}>
          {children}
        </Text>

        {cta && (
          <Box mt={12}>
            <PandaButton size="small" variant="primary" onClick={cta.onClick}>
              {cta.label}
            </PandaButton>
          </Box>
        )}
      </Box>

      {/* Tablet+ */}
      <Flex
        flexGrow={1}
        alignContent="center"
        justifyContent="space-between"
        display={{ base: 'none', sm: 'flex' }}
      >
        <Box mx="auto">
          <Flex alignItems="center">
            {icon && (
              <Icon
                color={iconColor}
                type={icon}
                size="20px"
                mr={8}
                alignSelf="flex-start"
                position="relative"
                top={4}
                flexShrink={0}
              />
            )}
            <Text
              as="h3"
              variant="body-md-500-caps"
              mb={4}
              flexGrow={1}
              display="inline"
              position="relative"
              data-testid={`${id}-title`}
            >
              {title}{' '}
            </Text>
          </Flex>
          <Text as="span" variant="body-md" data-testid={`${id}-text`}>
            {children}
          </Text>
          {cta && (
            <Button
              data-testid={`${id}-button`}
              size="small"
              variant="primary"
              onClick={cta.onClick}
              ml={12}
            >
              {cta.label}
            </Button>
          )}
        </Box>

        {onDismiss && (
          <Flex position="relative" top={4} display="block" pl={8}>
            <ResetButton onClick={onDismiss}>
              <Icon type="thick-cross" size={20} />
            </ResetButton>
          </Flex>
        )}
      </Flex>
    </Box>
  )
}
