import { createReducer } from '@reduxjs/toolkit'
import {
  setCardIsValid,
  setPaymentTokenInProgress,
  setPaymentToken,
  setCheckoutError,
  resetCheckout,
  setCheckoutSteps,
  setCheckoutSubmitting,
  setVoucherError,
  unsetVoucherError,
  setCheckoutValue,
} from '@mindfulchefuk/actions/checkoutActions'
import { ERROR_MESSAGE, IS_PENDING } from '@mindfulchefuk/constants'
import { CheckoutState } from '@mindfulchefuk/features/Checkout/interfaces'

export const initialState: CheckoutState = {
  activeCheckoutSteps: [],
  addressPickerActive: true,
  checkoutIsSubmitting: false,
  email: '',
  firstName: '',
  isCardValid: false,
  lastName: '',
  locality: '',
  marketingOptIn: false,
  password: '',
  paymentToken: '',
  paymentTokenRequestInProgress: false,
  postcode: '',
  recipientPhone: '',
  safePlace: '',
  safePlaceOther: '',
  street1: '',
  street2: '',
  voucherCode: '',
  partnershipName: '',
  [ERROR_MESSAGE]: undefined,
  [IS_PENDING]: false,
}

const checkoutReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCheckoutSteps, (state, action) => ({
      ...state,
      [IS_PENDING]: false,
      [ERROR_MESSAGE]: '',
      activeCheckoutSteps: [
        ...new Set([...state.activeCheckoutSteps, ...action.payload]),
      ],
    }))
    .addCase(setPaymentTokenInProgress, (state, action) => ({
      ...state,
      paymentTokenRequestInProgress: action.payload,
    }))
    .addCase(setPaymentToken, (state, action) => ({
      ...state,
      paymentToken: action.payload,
      paymentTokenRequestInProgress: false,
    }))
    .addCase(setCardIsValid, (state, action) => ({
      ...state,
      isCardValid: action.payload,
    }))
    .addCase(setCheckoutError, (state, action) => ({
      ...state,
      [IS_PENDING]: false,
      [ERROR_MESSAGE]: action.payload,
    }))
    .addCase(setCheckoutSubmitting, (state, action) => ({
      ...state,
      [IS_PENDING]: action.payload,
    }))
    .addCase(setCheckoutValue, (state, action) => ({
      ...state,
      ...(action.payload as object),
    }))
    .addCase(setVoucherError, (state, action) => ({
      ...state,
      voucherCode: '',
      [ERROR_MESSAGE]: action.payload,
    }))
    .addCase(unsetVoucherError, (state) => ({
      ...state,
      [ERROR_MESSAGE]: undefined,
    }))
    .addCase(resetCheckout, () => ({
      ...initialState,
    }))
    .addDefaultCase((state) => state)
})

export default checkoutReducer
