import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const CaloriesIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor">
        <path
          d="M5.708 4.896c-.088-1.39.558-2.726 1.706-3.528C8.594.558 10.07.292 11.462.639c-.658.272-1.165.814-1.389 1.485-.372 1.215.309 2.547 1.162 3.6.952.986 1.775 2.086 2.45 3.276.44 1.03.564 2.167.354 3.267-.23 1.572-1.026 3.007-2.242 4.041-1.336 1.076-3.077 1.526-4.773 1.233-1.611-.349-3.055-1.231-4.093-2.502-1.52-1.73-2.253-4.007-2.024-6.291.305-2.211 1.564-4.182 3.449-5.4-.897 1.575-.873 3.506.064 5.058"
          transform="translate(-930.000000, -507.000000) translate(-89.200000, 158.000000) translate(1019.200000, 54.000000) translate(0.000000, 0.000000) translate(0.000000, 295.000000) translate(3.025000, 1.000000)"
        />
        <path
          d="M4.447 16.461c-.947-1.755-1.236-3.786-.817-5.733.759.875 1.735 1.539 2.831 1.926-.337-2.297.278-4.632 1.707-6.471-.412.604-.48 1.377-.182 2.043.307.668.731 1.278 1.252 1.8.521.524.97 1.114 1.334 1.755.395.747.507 1.608.318 2.43-.117.632-.483 1.192-1.016 1.557-.576.365-1.313.365-1.888 0"
          transform="translate(-930.000000, -507.000000) translate(-89.200000, 158.000000) translate(1019.200000, 54.000000) translate(0.000000, 0.000000) translate(0.000000, 295.000000) translate(3.025000, 1.000000)"
        />
      </g>
    </g>
  </Svg>
)
