import { TFoodGroup, TMealPlan } from '@mindfulchefuk/config/mealPlanConfig'
import { RecipeSortId } from '@mindfulchefuk/config/sortOptionsConfig'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import {
  GetRecipeRecommendationParams,
  getRecipeRecommendations,
} from '@mindfulchefuk/features/Recipes/services/getRecipeRecommendations'
import { getRecipes } from '@mindfulchefuk/features/Recipes/services/getRecipes'
import { createAction } from '@reduxjs/toolkit'
import { Dispatch } from 'redux'

export const setExcludedFoodGroups = createAction<TFoodGroup[]>(
  'RECIPES_EXCLUDED_FOOD_GROUPS_SET'
)

export const setRecipesLoading = createAction<boolean>('RECIPES_LOADING_SET')

export const setMealPlan = createAction<TMealPlan>('RECIPES_MEAL_PLAN_SET')

export const resetRecipes = createAction('RECIPES_RESET')

export const setRecipes = createAction<Recipe[]>('RECIPES_SET')

export const sortRecipes = createAction<RecipeSortId>('RECIPES_SORT')

export const getRecipesByDateAndPortion =
  ({
    date,
    portionCount,
    excludedFoodGroups = [],
    customerId = '',
    mealPlan = 'Balanced',
  }: GetRecipeRecommendationParams) =>
  async (dispatch: Dispatch) => {
    dispatch(setRecipesLoading(true))

    const recipeRecommendations = await getRecipeRecommendations({
      date,
      portionCount,
      excludedFoodGroups,
      customerId,
      mealPlan,
      isOnboarding: true,
    })

    if (!recipeRecommendations.length) {
      dispatch(setRecipesLoading(false))
      dispatch(resetRecipes())

      return
    }

    const recipeIds = recipeRecommendations.map(
      (recipeRecommendation) => recipeRecommendation.recipeId
    )

    const recipes = await getRecipes({ recipeIds, portionCount })
    const mergedRecipes = recipeRecommendations
      .map<Recipe>((recipeRecommendation): Recipe => {
        const recipe = recipes.find(
          (r) => r.id === recipeRecommendation.recipeId
        )

        if (!recipe) return undefined
        return {
          ...recipe,
          sortOrder: recipeRecommendation.sortOrder,
          price: recipeRecommendation.price,
          pricePerPerson: recipeRecommendation.pricePerPerson,
          portions: recipeRecommendation.portions,
        }
      })
      .filter(Boolean)

    dispatch(setRecipes(mergedRecipes))
  }
