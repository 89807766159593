import { memo, splitProps } from '../helpers.mjs'
import { createRecipe, mergeRecipes } from './create-recipe.mjs'

const pandaButtonFn = /* @__PURE__ */ createRecipe(
  'pandaButton',
  {
    variant: 'primary-square',
    size: 'medium',
    fullWidth: false,
    textWrap: false,
  },
  []
)

const pandaButtonVariantMap = {
  size: ['small', 'medium', 'large'],
  variant: [
    'primary-square',
    'primary',
    'primary-white',
    'secondary',
    'secondary-white',
    'bbc-good-food',
  ],
  fullWidth: ['true', 'false'],
  textWrap: ['true', 'false'],
}

const pandaButtonVariantKeys = Object.keys(pandaButtonVariantMap)

export const pandaButton = /* @__PURE__ */ Object.assign(
  memo(pandaButtonFn.recipeFn),
  {
    __recipe__: true,
    __name__: 'pandaButton',
    __getCompoundVariantCss__: pandaButtonFn.__getCompoundVariantCss__,
    raw: (props) => props,
    variantKeys: pandaButtonVariantKeys,
    variantMap: pandaButtonVariantMap,
    merge(recipe) {
      return mergeRecipes(this, recipe)
    },
    splitVariantProps(props) {
      return splitProps(props, pandaButtonVariantKeys)
    },
    getVariantProps: pandaButtonFn.getVariantProps,
  }
)
