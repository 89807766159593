import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const ClockIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 22 20" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="currentColor">
        <path
          d="M18.836 7.66c-.257-1.271-.884-2.44-1.805-3.36-1.712-2.02-4.293-3.108-6.948-2.93-2.677.15-5.163 1.422-6.836 3.5-2.34 3.003-2.551 7.132-.528 10.354 2.023 3.221 5.853 4.855 9.603 4.096 0 0 7.522-.91 7.058-9.28"
          transform="translate(-1043.000000, -507.000000) translate(-89.200000, 158.000000) translate(1019.200000, 54.000000) translate(0.000000, 0.000000) translate(0.000000, 295.000000) translate(113.941667, 0.000000)"
        />
        <path
          d="M10.083 5.29l.273 6.19c2.37-.86 4.667-1.91 6.866-3.14"
          transform="translate(-1043.000000, -507.000000) translate(-89.200000, 158.000000) translate(1019.200000, 54.000000) translate(0.000000, 0.000000) translate(0.000000, 295.000000) translate(113.941667, 0.000000)"
        />
      </g>
    </g>
  </Svg>
)
