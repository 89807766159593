import React, { ReactElement } from 'react'
import { Global } from '@emotion/core'
import { theme } from './theme'

export const GlobalStyles = (): ReactElement => (
  <Global
    styles={{
      body: {
        ...theme.typography['body-lg'],
        lineHeight: 1.33,
        color: theme.colors.aubergine,
      },
      a: {
        textDecoration: 'none',
        cursor: 'pointer',
      },
    }}
  />
)
