import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const RoundelInfoIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 20" fill="none" {...props}>
    <path
      d="M19.9975 10.1986C19.6784 7.55806 18.5296 5.3117 16.6131 3.46044C15.5161 2.40078 14.2333 1.6499 12.752 1.24824C11.7931 0.988226 10.8186 0.958305 9.83414 1.04085C8.22433 1.17585 6.68978 1.57908 5.28029 2.37059C2.7087 3.81464 1.14127 5.92351 1.00935 8.94183C0.99924 9.17389 0.997042 9.40574 1.00416 9.6371C1.00702 9.72962 1.01133 9.82205 1.01717 9.9144C1.02544 10.044 1.03696 10.1733 1.05159 10.3024C1.05392 10.3228 1.05638 10.3433 1.05885 10.3637C1.07107 10.4642 1.08535 10.5645 1.1017 10.6646C1.11265 10.7317 1.12439 10.7986 1.13727 10.8655C1.13815 10.87 1.13889 10.8745 1.13977 10.8791C1.15757 10.9704 1.17722 11.0616 1.19881 11.1525C1.20553 11.2108 1.21309 11.2688 1.22175 11.3265C1.23947 11.4444 1.2629 11.5609 1.29661 11.675C1.31129 11.7247 1.32795 11.7739 1.34712 11.8225C1.35639 11.846 1.36576 11.8695 1.37626 11.8927C1.37701 11.901 1.37745 11.9092 1.37789 11.9176C1.37881 11.9364 1.37938 11.9553 1.3818 11.9737C1.38299 11.9826 1.38461 11.9913 1.38694 11.9999C1.76036 13.3705 2.38645 14.615 3.28177 15.7214C3.33742 15.7902 3.39245 15.8598 3.44832 15.9288C3.49131 15.9818 3.53514 16.0342 3.57971 16.0859C3.70908 16.2357 3.84768 16.3773 4.01203 16.4941C4.01687 16.5046 4.02069 16.5161 4.02478 16.5274C4.02816 16.5367 4.03172 16.5459 4.03634 16.554C4.03968 16.5599 4.04359 16.5653 4.04834 16.5698C4.71873 17.2074 5.45137 17.757 6.30093 18.1403C6.33777 18.164 6.37557 18.1855 6.41412 18.2053C6.52986 18.2647 6.65251 18.3083 6.77699 18.3477C7.00016 18.4548 7.22927 18.5443 7.46677 18.6092C7.47679 18.6119 7.48668 18.615 7.49671 18.6177C7.57948 18.6394 7.66326 18.6582 7.74819 18.6734C7.76212 18.683 7.77676 18.6899 7.79166 18.6958C7.86085 18.7233 7.93843 18.7181 8.00907 18.74C8.35361 18.8351 8.70443 18.8919 9.05847 18.919C9.17645 18.928 9.29482 18.9337 9.41338 18.9363C10.4409 18.9597 11.4634 18.9014 12.4741 18.7007C13.0312 18.5902 13.5904 18.4878 14.1219 18.2782C14.9861 18.0344 15.7957 17.6651 16.5542 17.1914C19.1511 15.5694 20.3673 13.2583 19.9975 10.1986"
      stroke="#392D6E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3099 4.34538C10.0439 4.60077 9.86457 4.93295 9.79688 5.29538C9.79688 5.69938 10.1244 6.02688 10.5284 6.02688C10.9037 6.00003 11.2279 5.75414 11.3549 5.39988C11.472 5.14678 11.472 4.85499 11.3549 4.60188C11.1665 4.32347 10.8296 4.18499 10.4999 4.25038C10.1769 4.25038 9.88238 4.56388 9.95838 4.82988"
      stroke="#392D6E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9084 8.51953L10.7564 15.293"
      stroke="#392D6E"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)
