import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

const path =
  'M8,6.41c1.11-2.13,1.8-3.2,2.81-5.23,1,1.77,2.54,6,2.77,6.11s4.51.5,6.12.83c-1,1.2-4.08,3.48-5,4.41.3,1.45,1.4,5.53,1.75,7.26-2-1.21-4.23-2.93-5.92-4-2,1.24-4.21,3.14-6.28,4.08.55-2.58.89-4.86,1.43-7.24-1.4-1.36-3.56-2.9-5-4.26L6,7.14'

export const StarIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 21" data-testid="customer-favourite-icon" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d={path} />
    </g>
  </Svg>
)

export const StarIconFilled = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 21 21" data-testid="customer-favourite-icon" {...props}>
    <g
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d={path} />
    </g>
  </Svg>
)
