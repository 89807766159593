import React, { ReactElement } from 'react'
import { colors } from '@mindfulchefuk/design-system/system/theme'

export const RadioIcon = ({ checked }: { checked: boolean }): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 -1 20 20"
    color={colors.aubergine}
  >
    {checked ? (
      <g stroke="currentColor" fill="none">
        <path
          stroke="currentColor"
          d="M17.587 9.011c-.295-2.587-1.359-4.787-3.133-6.6C13.438 1.371 12.25.636 10.879.242 9.992-.012 9.09-.04 8.18.04 6.687.172 5.266.567 3.961 1.343 1.582 2.757.131 4.823.01 7.78c-.01.227-.012.455-.005.681.002.09.006.181.012.272.008.127.018.253.032.38l.006.06c.012.098.025.197.04.295l.033.197.002.013c.017.09.035.179.055.268.006.057.013.114.021.17.017.116.038.23.07.342.013.048.029.096.046.144l.027.069.002.024c0 .019.001.037.003.055l.005.026c.346 1.343.925 2.562 1.754 3.646l.155.203.121.154c.12.146.248.285.4.4.005.01.008.021.012.032l.01.026.012.016c.62.624 1.299 1.163 2.085 1.538.034.023.07.045.105.064.107.058.22.1.336.14.207.104.419.192.639.256l.027.008c.077.021.154.04.233.054.013.01.027.017.04.022.064.027.136.022.202.044.319.093.643.148.971.175.11.009.219.014.329.017.95.023 1.897-.034 2.833-.23.516-.11 1.034-.21 1.526-.415.8-.239 1.55-.6 2.251-1.064 2.404-1.59 3.53-3.853 3.188-6.85"
          strokeWidth="1.2"
        />
        <path
          fill="currentColor"
          d="M13.497 8.903c-.157-1.338-.724-2.476-1.67-3.414-.542-.537-1.176-.917-1.907-1.12-.473-.133-.955-.148-1.44-.106-.795.069-1.553.273-2.249.674-1.27.732-2.044 1.8-2.109 3.33-.005.117-.006.235-.002.352.001.047.003.094.006.14.004.066.01.132.017.197l.004.031.02.153.018.101.002.007c.008.047.018.093.029.139l.011.088c.009.06.02.119.037.177.007.025.016.05.025.074l.014.036.001.012.002.029.003.013c.184.695.493 1.325.935 1.886l.082.105.065.08c.064.075.133.147.214.206l.006.017.006.014.006.008c.33.323.692.601 1.112.796.018.012.037.022.056.032.057.03.117.053.179.073.11.054.223.1.34.132l.015.004c.041.011.083.02.124.029.007.005.015.008.022.011.034.014.072.011.107.022.17.049.344.077.518.091.059.005.117.008.176.009.507.012 1.012-.018 1.51-.12.276-.056.552-.107.814-.214.427-.123.827-.31 1.201-.55 1.282-.822 1.883-1.993 1.7-3.544"
        />
      </g>
    ) : (
      <g fill="none" stroke="currentColor">
        <path
          d="M17.587 9.011c-.295-2.587-1.359-4.787-3.133-6.6C13.438 1.371 12.25.636 10.879.242 9.992-.012 9.09-.04 8.18.04 6.687.172 5.266.567 3.961 1.343 1.582 2.757.131 4.823.01 7.78c-.01.227-.012.455-.005.681.002.09.006.181.012.272.008.127.018.253.032.38l.006.06c.012.098.025.197.04.295l.033.197.002.013c.017.09.035.179.055.268.006.057.013.114.021.17.017.116.038.23.07.342.013.048.029.096.046.144l.027.069.002.024c0 .019.001.037.003.055l.005.026c.346 1.343.925 2.562 1.754 3.646l.155.203.121.154c.12.146.248.285.4.4.005.01.008.021.012.032l.01.026.012.016c.62.624 1.299 1.163 2.085 1.538.034.023.07.045.105.064.107.058.22.1.336.14.207.104.419.192.639.256l.027.008c.077.021.154.04.233.054.013.01.027.017.04.022.064.027.136.022.202.044.319.093.643.148.971.175.11.009.219.014.329.017.95.023 1.897-.034 2.833-.23.516-.11 1.034-.21 1.526-.415.8-.239 1.55-.6 2.251-1.064 2.404-1.59 3.53-3.853 3.188-6.85"
          stroke="currentColor"
          strokeWidth="1.2"
        />
        <path
          d="M13.497 8.903c-.157-1.338-.724-2.476-1.67-3.414-.542-.537-1.176-.917-1.907-1.12-.473-.133-.955-.148-1.44-.106-.795.069-1.553.273-2.249.674-1.27.732-2.044 1.8-2.109 3.33-.005.117-.006.235-.002.352.001.047.003.094.006.14.004.066.01.132.017.197l.004.031.02.153.018.101.002.007c.008.047.018.093.029.139l.011.088c.009.06.02.119.037.177.007.025.016.05.025.074l.014.036.001.012.002.029.003.013c.184.695.493 1.325.935 1.886l.082.105.065.08c.064.075.133.147.214.206l.006.017.006.014.006.008c.33.323.692.601 1.112.796.018.012.037.022.056.032.057.03.117.053.179.073.11.054.223.1.34.132l.015.004c.041.011.083.02.124.029.007.005.015.008.022.011.034.014.072.011.107.022.17.049.344.077.518.091.059.005.117.008.176.009.507.012 1.012-.018 1.51-.12.276-.056.552-.107.814-.214.427-.123.827-.31 1.201-.55 1.282-.822 1.883-1.993 1.7-3.544"
          fill="currentColor"
          opacity=".2"
        />
      </g>
    )}
  </svg>
)
