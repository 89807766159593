import { createReducer } from '@reduxjs/toolkit'

import {
  addItem,
  addItems,
  applyDiscount,
  clearLastAddedRecipe,
  postLoginApplyDiscount,
  removeItem,
  resetBasket,
  resetItems,
  revokeDiscount,
  setBasketLoading,
  setBasketMealPlan,
  setBasketShippingPrice,
  setDeliveryDate,
  setPortionCount,
  setVoucherCode,
  unsetVoucherCode,
} from '@mindfulchefuk/actions/basketActions'
import arrayRemoveLastMatch from '@mindfulchefuk/utils/array/arrayRemoveLastMatch'
import getTotal from '@mindfulchefuk/utils/getTotal'
import { BasketState } from '@mindfulchefuk/features/Basket'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'

const initialState: BasketState = {
  items: [],
  itemIds: [],
  portionCount: 2,
  selectedMealPlan: 'Balanced',
  total: 0,
  savedVoucherCode: '',
  discountAmount: 0,
  totalWithDiscount: 0,
  creditBalanceAmount: 0,
  recipeShippingPrice: 0,
  standardRecipeShippingPrice: null,
  discountApplied: false,
  creditApplied: false,
  isBasketLoading: false,
  lastAddedRecipeId: '',
  selectedDeliveryDate: '',
}

const basketReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(
      setDeliveryDate,
      (state, action): BasketState => ({
        ...state,
        selectedDeliveryDate: action.payload,
      })
    )
    .addCase(addItem, (state, action): BasketState => {
      const updatedItems = [...state.items, action.payload]

      return {
        ...state,
        items: updatedItems,
        itemIds: [...state.itemIds, action.payload.id],
        total: getTotal(updatedItems.map((item) => item.price)),
        lastAddedRecipeId: action.payload.id,
      }
    })
    .addCase(addItems, (state, action) => {
      const updatedItems = [...state.items, ...action.payload]
      const updatedItemIds = action.payload.map((item: Recipe) => item.id)

      return {
        ...state,
        items: updatedItems,
        itemIds: [...state.itemIds, ...updatedItemIds],
        total: getTotal(updatedItems.map((item) => item.price)),
      }
    })
    .addCase(removeItem, (state, action) => {
      const updatedItems = arrayRemoveLastMatch(
        state.items,
        action.payload.id,
        (item, id) => item.id === id
      )
      const updatedItemIds = arrayRemoveLastMatch(
        state.itemIds,
        action.payload.id
      )
      return {
        ...state,
        items: updatedItems,
        itemIds: updatedItemIds,
        total: getTotal(updatedItems.map((item: Recipe) => item.price)),
      }
    })
    .addCase(resetItems, (state) => ({
      ...state,
      items: [],
      itemIds: [],
      total: 0,
      totalWithDiscount: 0,
      discountApplied: false,
      creditApplied: false,
    }))
    .addCase(setPortionCount, (state, action) => {
      const portionCount = action.payload

      return {
        ...state,
        portionCount,
      }
    })
    .addCase(setBasketMealPlan, (state, action) => ({
      ...state,
      selectedMealPlan: action.payload,
    }))
    .addCase(setBasketShippingPrice, (state, action) => ({
      ...state,
      recipeShippingPrice: action.payload.shippingPrice,
      standardRecipeShippingPrice: action.payload.standardShippingPrice,
    }))
    .addCase(setVoucherCode, (state, action) => ({
      ...state,
      savedVoucherCode: action.payload,
    }))
    .addCase(unsetVoucherCode, (state) => ({
      ...state,
      savedVoucherCode: initialState.savedVoucherCode,
    }))
    .addCase(applyDiscount, (state, action) => ({
      ...state,
      discountAmount: action.payload.discountAmount,
      discountApplied: !!action.payload.discountAmount,
      total: action.payload.recipeTotal,
      totalWithDiscount: state.total ? action.payload.discountedPrice : 0,
    }))
    .addCase(postLoginApplyDiscount, (state, action) => ({
      ...state,
      creditBalanceAmount: action.payload.creditBalanceAmount,
      discountAmount: action.payload.discountAmount,
      discountApplied: !!action.payload.discountAmount,
      creditApplied: !!action.payload.creditBalanceAmount,
      totalWithDiscount: action.payload.paymentAmount,
    }))
    .addCase(revokeDiscount, (state) => ({
      ...state,
      discountAmount: 0,
      discountApplied: false,
      creditApplied: false,
      totalWithDiscount: 0,
    }))
    .addCase(resetBasket, () => ({
      ...initialState,
    }))
    .addCase(clearLastAddedRecipe, (state) => ({
      ...state,
      lastAddedRecipeId: '',
    }))
    .addCase(setBasketLoading, (state, action) => ({
      ...state,
      isBasketLoading: action.payload,
    }))
    .addDefaultCase((state) => state)
})

export default basketReducer
