import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { TypographyToken } from '../system/theme/typography'
import { BoxComponent } from '../layout/Box'
import { IText, Text } from '../Text/Text'
import { colors } from '../system/theme'
import { Responsive } from '../system/types'

interface LinkProps extends IText {
  variant?: Responsive<
    Extract<
      TypographyToken,
      | 'body-xl'
      | 'body-lg'
      | 'body-md'
      | 'body-sm'
      | 'body-xs'
      | 'body-xl-500'
      | 'body-lg-500'
      | 'body-md-500'
      | 'body-sm-500'
      | 'body-xs-500'
      | 'body-xl-500-caps'
      | 'body-lg-500-caps'
      | 'body-md-500-caps'
      | 'body-sm-500-caps'
      | 'body-xs-500-caps'
    >
  >
}

export const linkStyles = css`
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    color: ${colors['aubergine-300']};
    cursor: not-allowed;
    text-decoration: underline;
  }
`

export const Anchor = styled(Text)() as unknown as BoxComponent<'a', IText>
Anchor.defaultProps = { as: 'a' }

export const Link = styled(Text)`
  ${linkStyles}
` as unknown as BoxComponent<'a', LinkProps>

Link.defaultProps = { as: 'a', color: 'broccoli' }

export const ButtonLink = styled(Link)`
  border: none;
  cursor: pointer;
  background: transparent;
  padding: 0;
  ${linkStyles}
` as unknown as BoxComponent<'button', LinkProps>

ButtonLink.defaultProps = { as: 'button', color: 'broccoli' }
