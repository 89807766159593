import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const VenisonIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.44.66a6.31,6.31,0,0,0,.25,3.82A5.14,5.14,0,0,0,7.5,7.26a5.92,5.92,0,0,0,5.84-1.19A7.09,7.09,0,0,0,15.55.81" />
      <path d="M12.35.91a6.31,6.31,0,0,0,.73,2.45,2.89,2.89,0,0,0,.8,1,2.48,2.48,0,0,0,2.35.18A3.15,3.15,0,0,0,17.85,2.9,5.26,5.26,0,0,0,18.23.68" />
      <path d="M1.47.74A3.54,3.54,0,0,0,2.11,3a3.12,3.12,0,0,0,3.56.87A2.62,2.62,0,0,0,7.21.76" />
      <path d="M7.41,9c-.68-1.07-4-1.45-4-1.11a3.57,3.57,0,0,0,1.13,2.71c.44.31.9.41.93.95.22,5,1.41,7.77,2.49,8.09a9.86,9.86,0,0,0,4.1,0c1.54-.26,2.35-3.67,2.28-8.57.89-.25,2.14-1,1.69-3.76A3.78,3.78,0,0,0,12.1,9.05a3.45,3.45,0,0,0-3.4-.17" />
    </g>
  </Svg>
)
