import React, { ReactElement } from 'react'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const LambIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.33,7.2a3.25,3.25,0,0,1-2.81-.14c-.62-.35-1.44.45-2.15.44s-1.22-.09-1.48-.6a1.7,1.7,0,0,1-2.07.54,1.85,1.85,0,0,1-.93-2A1.75,1.75,0,0,1,4.56,4.07,2.49,2.49,0,0,1,5.87,1.55a2.28,2.28,0,0,1,2.68.59,2.26,2.26,0,0,1,3.11-.09,2,2,0,0,1,2.5-.57,2.25,2.25,0,0,1,1.09,2.43,2,2,0,0,1,2.51,1.37,2,2,0,0,1-1.54,2.07c-.89.24-1.48-.28-2.36-.57" />
      <path d="M4.38,9a14,14,0,0,0,1,7,5.54,5.54,0,0,0,5.38,3.07,4.59,4.59,0,0,0,3.37-1.62c1.61-2.22,2-5.82,1.79-8.67" />
      <path d="M2.8,7.42a1.35,1.35,0,0,0-1.28-.11,1.4,1.4,0,0,0-.8,1,1.44,1.44,0,0,0,0,.62A1.33,1.33,0,0,0,2,9.85,1.34,1.34,0,0,0,3.21,9" />
      <path d="M17.13,8.71a1.41,1.41,0,0,0,.83,1,1.39,1.39,0,0,0,1.28-.16,1.27,1.27,0,0,0,.42-.47,1.34,1.34,0,0,0-.2-1.48A1.32,1.32,0,0,0,18,7.29" />
      <path d="M9.06,16.41a1.91,1.91,0,0,0,2.33,0" />
    </g>
  </Svg>
)
