import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const SearchIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 20 20" {...props} fill="none">
    <path
      d="M16.5336 16.9263C16.5336 16.9263 12.6416 12.6803 12.6376 12.6753C12.7506 12.7993 10.9156 13.5783 10.7886 13.6253C9.95064 13.9353 9.15564 14.1543 8.25064 14.1043C6.46664 14.0073 4.71264 13.0553 3.75764 11.5293C1.69664 8.23433 4.07864 3.40033 8.03164 3.02233C11.5016 2.69133 14.5066 6.09733 14.0546 9.48733"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.073"
    />
  </Svg>
)
