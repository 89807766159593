import React, { ReactElement } from 'react'

import { RadioButtonLabel } from '@mindfulchefuk/design-system/Forms/RadioButton/RadioButton.styles'
import { RadioIcon } from '@mindfulchefuk/design-system/Forms/RadioButton/RadioIcon'
import { TInput } from '@mindfulchefuk/design-system/Forms/types'
import { Box } from '@mindfulchefuk/design-system/layout/Box'
import { HiddenInput } from '@mindfulchefuk/design-system/Forms/form.styles'

export const RadioButton = ({
  children,
  checked,
  disabled,
  'data-testid': testId,
  ...input
}: TInput): ReactElement => (
  <RadioButtonLabel disabled={disabled} data-testid={testId}>
    <HiddenInput
      {...input}
      checked={checked}
      disabled={disabled}
      type="radio"
    />

    <Box size={28}>
      <RadioIcon checked={checked} />
    </Box>

    <Box as="span" mt={2} data-testid={`${testId}-label`}>
      {children}
    </Box>
  </RadioButtonLabel>
)
