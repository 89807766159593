import React, { ReactElement } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

export const SectionBackground06 = (): ReactElement => (
  <>
    <Illustration
      type="chopped-carrot-twirl"
      top={['-60px', '0px']}
      left={['-10px', '-10px']}
      transform="scale(1) rotate(0deg)"
    />
    <Illustration
      type="chopped-beetroot-three"
      top={['-54px', '0px']}
      right={['-58px', '-40px']}
      left={[null, null]}
      transform="rotate(-20deg)"
    />
  </>
)
