import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const ChefIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 17 17" {...props}>
    <g stroke="currentColor" fill="none">
      <path d="M3.573 9.815c0 .945.056 1.485.056 2.43 0 .572-.247 1.835.15 2.287.237.236.533.4.858.477 2.031.69 4.214.803 6.305.325a4.768 4.768 0 001.74-.619c.492-.453.349-1.144.373-1.85.047-1.128.11-2.256.11-3.383 0-1.382-.237-1.279.835-1.7.2-.085.388-.2.556-.341a3.003 3.003 0 001.095-2.01 3.073 3.073 0 00-1.588-2.898c-.317-.15-1.46-.675-2.064-.15" />
      <path d="M10.903 7.44a5.313 5.313 0 001.254-3.176 3.452 3.452 0 00-.794-2.509C9.41-.31 4.256 1.048 4.907 4.431" />
      <path d="M5.09 2.382c-1.46-.394-3 .262-3.725 1.589a3.084 3.084 0 000 2.74c.858 1.786 2.502 1.834 4.146 1.373m-.405 5.082s.667.93 3.35.691" />
    </g>
  </Svg>
)
