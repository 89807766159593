import React, { ReactElement } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

export const SectionBackground05 = (): ReactElement => (
  <>
    <Illustration
      type="cross-section-artichoke"
      bottom={['-180px', '-130px']}
      left={['-70px', '-65px']}
      transform="scale(2) rotate(20deg)"
    />
    <Illustration
      type="chopped-tomato-two"
      bottom={['-130px', '130px']}
      right={['-10px']}
      left={[null, '-25px']}
      transform="scale(1.35)"
    />
    <Illustration
      type="chopped-beetroot-one"
      transform="rotate(25deg)"
      bottom={['-25px', '250px']}
      right={['55px']}
      left={[null, '50px']}
      display={{ base: 'none', md: 'block' }}
    />
    <Illustration
      type="chopped-beetroot-one"
      transform="rotate(70deg)"
      bottom={[null, '360px']}
      left={[null, '-35px']}
      display={{ base: 'none', md: 'block' }}
    />
    <Illustration
      type="cross-section-squash-one"
      top={[null, '53px']}
      right={[null, '-25px']}
      display={['none', 'block']}
    />
    <Illustration
      type="cross-section-squash-one"
      top={[null, '120px']}
      right={[null, '-75px']}
      display={['none', 'block']}
    />
    <Illustration
      type="cross-section-cucumber"
      bottom={[null, '70px']}
      right={[null, '-185px']}
      display={{ base: 'none', lg: 'block' }}
      transform="scale(1.25)"
    />
    <Illustration
      type="cross-section-turnip"
      bottom={[null, '-70px']}
      right={[null, '-24px']}
      display={['none', 'block']}
      transform="scale(1.5)"
    />
  </>
)
