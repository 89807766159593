import { ActionCreator } from 'redux'
import get from 'lodash/get'

import { PaymentsOnboardingOrderPreview } from '@mindfulchefuk/api-client'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import errorHandler from '@mindfulchefuk/utils/errorHandler'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import { Recipe } from '@mindfulchefuk/features/Recipes/interfaces'
import { RootState } from '@mindfulchefuk/types/store'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { postOrderPreview } from '@mindfulchefuk/services/postOrderPreview'
import { createAction } from '@reduxjs/toolkit'
import { TMealPlan } from '@mindfulchefuk/config/mealPlanConfig'
import { PortionCount } from '@mindfulchefuk/config/portionCountConfig'

export const setDeliveryDate = createAction<string>('BASKET_DELIVERY_DATE_SET')

export const addItem = createAction<Recipe>('BASKET_ITEM_ADD')

export const addItems = createAction<Recipe[]>('BASKET_ITEMS_ADD')

export const applyDiscount = createAction<{
  discountAmount: number
  recipeTotal: number
  discountedPrice: number
}>('BASKET_DISCOUNT_APPLY')

export const setBasketShippingPrice = createAction<{
  shippingPrice: number
  standardShippingPrice: number
}>('BASKET_SET_SHIPPING_PRICE')

export const postLoginApplyDiscount = createAction<{
  creditBalanceAmount: number
  discountAmount: number
  paymentAmount: number
}>('BASKET_POST_LOGIN_DISCOUNT_APPLY')

export const removeItem = createAction<Recipe>('BASKET_ITEM_REMOVE')

export const resetItems = createAction('BASKET_ITEMS_RESET')

export const resetBasket = createAction('BASKET_RESET')

export const setPortionCount = createAction<PortionCount>(
  'BASKET_PORTION_COUNT_SET'
)

export const setVoucherCode = createAction<string>('BASKET_VOUCHER_CODE_SET')

export const unsetVoucherCode = createAction('BASKET_VOUCHER_CODE_UNSET')

export const revokeDiscount = createAction('BASKET_DISCOUNT_REVOKE')

export const setBasketLoading = createAction<boolean>('SET_BASKET_LOADING')

export const setBasketMealPlan = createAction<TMealPlan>('BASKET_MEAL_PLAN_SET')

export const clearLastAddedRecipe = createAction(
  'BASKET_CLEAR_LAST_ADDED_RECIPE'
)

export const updateBasket =
  (recipe: Recipe, action: 'add' | 'remove'): ActionCreator<void> =>
  async (dispatch) => {
    dispatch(setBasketLoading(true))

    if (action === 'add') {
      dispatch(addItem(recipe))
    } else if (action === 'remove') {
      dispatch(removeItem(recipe))
    }

    await dispatch(repopulateBasket())
    dispatch(setBasketLoading(false))
  }

export const applyVoucher = (
  code: string | undefined,
  suppressErrors = false
): ActionCreator<Promise<void>> => {
  if (!code) return

  return async (dispatch, getState) => {
    try {
      const {
        basket: { items, selectedDeliveryDate: selectedDelivery },
      } = getState() as RootState

      const recipes = items.map(({ id, portions }) => {
        return { recipe_id: id, portions }
      })

      const vouchersDiscountPreviewData = {
        code_string: code,
        delivery_date: selectedDelivery,
        recipe_contents: recipes,
      }

      const POST = new PaymentsOnboardingOrderPreview(
        vouchersDiscountPreviewData
      )

      const handleSuccess = () => {
        const {
          code_string: voucherCode,
          recipe_discount_amount: responseDiscountAmount,
          recipe_purchase_value: responseRecipeTotal,
          recipe_external_payment_amount: responseDiscountedPrice,
          recipe_shipping_amount: responseShippingPrice,
          recipe_standard_shipping_amount: responseStandardShippingPrice,
        } = POST

        const discountAmount = parseFloat(responseDiscountAmount)
        const recipeTotal = parseFloat(responseRecipeTotal)
        const discountedPrice = parseFloat(responseDiscountedPrice)
        const shippingPrice = parseFloat(responseShippingPrice)
        const standardShippingPrice = parseFloat(responseStandardShippingPrice)

        /* add name to store */
        dispatch(setVoucherCode(voucherCode))
        /* make price calculations and add discount info amount to store */
        dispatch(
          applyDiscount({ discountAmount, recipeTotal, discountedPrice })
        )
        dispatch(
          setBasketShippingPrice({ shippingPrice, standardShippingPrice })
        )
      }

      const handleError = () => {
        logEvent({
          category: ANALYTICS_CATEGORIES.onboarding,
          action: 'InvalidVoucherCode',
          label: 'Payment Details',
        })

        const errorCode = get(POST, 'errors.code_string.rawPayload.meta.code')

        return Promise.reject(errorCode)
      }

      const success = await POST.save()

      if (success) {
        handleSuccess()
      } else {
        await handleError()
      }
    } catch (error) {
      errorHandler(error, { suppress: suppressErrors })
    }
  }
}

export const applyPostLoginDiscounts = (): ActionCreator<void> => {
  return async (dispatch, getState) => {
    const {
      basket: { items, selectedDeliveryDate: selectedDelivery },
    } = getState() as RootState

    const recipeContents = items.map(({ id, portions, price }) => {
      return { recipe_id: id, portions, price }
    })

    // Customers with existing accounts are not eligible for voucher codes,
    // so we remove them before fetching account-assigned discounts and credits
    dispatch(unsetVoucherCode())
    dispatch(revokeDiscount())

    const customerId = getCustomerId()

    try {
      const response = await postOrderPreview({
        customerId,
        deliveryDate: selectedDelivery,
        recipeContents,
        productContents: [],
      })

      dispatch(
        postLoginApplyDiscount({
          creditBalanceAmount: response.creditBalanceAmount,
          discountAmount: response.discountAmount,
          paymentAmount: response.externalPaymentAmount,
        })
      )
    } catch (err) {
      errorHandler(err)
    }
  }
}

/*
  will populate the basket in redux store (items, itemIds etc.)
  by checking the selected recipeIds
*/
export const repopulateBasket = (): ActionCreator<void> => {
  return async (dispatch, getState) => {
    const {
      recipes: { allRecipes },
      basket: { itemIds, savedVoucherCode },
    } = getState() as RootState

    const customerId = getCustomerId()

    dispatch(resetItems())

    // Remove any recipes if they aren't visible
    const basketItems = itemIds
      .map((id) => allRecipes.find((recipe) => id === recipe.id))
      .filter(Boolean)

    dispatch(addItems(basketItems))

    if (customerId) {
      await dispatch(applyPostLoginDiscounts())
    } else if (savedVoucherCode) {
      await dispatch(applyVoucher(savedVoucherCode))
    }
  }
}
