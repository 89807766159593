import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const CheckboxTickedIcon = (props: BoxProps): ReactElement => (
  <Svg
    viewBox="0 0 30 30"
    data-testid="checkbox-ticked-icon"
    fill="none"
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72266 12.5516C2.53808 17.6544 2.72266 19.3498 2.72266 24.1866C2.70535 25.4416 3.10722 26.6479 3.83009 27.5109C4.67507 28.2265 5.69103 28.5497 6.70414 28.4251L24.7658 28.8406C25.5129 28.9766 26.2721 28.7314 26.8752 28.1592C27.5081 27.3613 27.4026 25.3003 27.429 24.4526C27.5344 20.7127 27.6531 18.286 27.7585 14.4797L27.9563 7.83105C28.0975 6.70747 27.8897 5.55851 27.3762 4.6231C26.6405 3.85566 25.6878 3.5013 24.7395 3.64243L7.40288 3.60919C6.43813 3.50293 5.46657 3.66171 4.55519 4.07459C2.9336 4.97215 2.72266 9.73942 2.72266 12.5516Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.64746 18.8212C8.64746 18.8212 12.1574 22.0807 12.9702 22.0807C13.7831 22.0807 21.5975 9.63672 21.5975 9.63672"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Svg>
)
