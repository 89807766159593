import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const HeartIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 17 17" {...props}>
    <path
      d="M7.361 2.179a3.337 3.337 0 00-3.672-.982A4.071 4.071 0 001.66 3.073a6.29 6.29 0 00-.192 5.19 13.2 13.2 0 003.114 4.447c1.277 1.277 3.146 2.874 4.655 2.802 1.508-.072 3.672-2.188 4.702-3.72A10.486 10.486 0 0016 6.138c0-1.063-.32-2.1-.918-2.978a4.196 4.196 0 00-2.61-1.717 4.174 4.174 0 00-3.61 1.182 4.852 4.852 0 00-1.341 3.52c-.067.62.2 1.228.703 1.597.56.254 1.221.09 1.596-.399.346-.498.451-1.125.288-1.708a6.035 6.035 0 00-.687-1.597"
      stroke="currentColor"
      fill="none"
    />
  </Svg>
)
