import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg/Svg'

export const TickIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 15 15" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M13.902.185a.633.633 0 00-.481 0 .62.62 0 00-.35.334 29.209 29.209 0 01-1.22 2.382l-1.324 2.34A111.711 111.711 0 017.74 9.823a36.221 36.221 0 01-1.506 2.181c-.237.331-.503.64-.794.924a.277.277 0 01-.045.035 2.152 2.152 0 01-.471-.217 15.005 15.005 0 01-2.041-1.567c-.313-.265-.633-.567-.98-.893l-.516-.497a3.38 3.38 0 00-.492-.426.545.545 0 00-.845.344.53.53 0 00.077.413c.135.194.291.37.466.53l.466.468c.357.341.683.646 1.003.931.69.643 1.44 1.217 2.24 1.716.235.14.488.254.751.333.087.026.175.043.265.05.056.005.111.005.167 0a.846.846 0 00.264-.052c.158-.062.303-.152.43-.265l.042-.037a7.06 7.06 0 00.897-1.035l.06-.08c.56-.757 1.06-1.543 1.523-2.276a127.916 127.916 0 002.758-4.638L12.8 3.42c.412-.739.865-1.53 1.376-2.314a.633.633 0 00-.273-.921z"
    />
  </Svg>
)
