import styled from '@emotion/styled'
import React, { ReactNode } from 'react'
import {
  SectionBackground01,
  SectionBackground02,
  SectionBackground04,
  SectionBackground05,
  SectionBackground06,
  SectionBackground07,
  SectionBackground08,
  SectionBackground10,
  SectionBackground13,
  SectionBackground14,
  SectionBackground15,
  SectionBackground16,
} from '../Backgrounds'
import { Box, BoxProps } from './Box'
import {
  CONTAINER_MAX_WIDTH,
  CONTAINER_MIN_WIDTH,
  CONTAINER_PADDING,
} from './consts'

export const Container = styled(Box)()

Container.defaultProps = {
  mx: 'auto',
  px: CONTAINER_PADDING,
  minWidth: CONTAINER_MIN_WIDTH,
  maxWidth: CONTAINER_MAX_WIDTH,
}

export interface IllustrationContainerProps extends BoxProps {
  children?: ReactNode
  illustrations?:
    | '01'
    | '02'
    | '04'
    | '05'
    | '06'
    | '07'
    | '08'
    | '10'
    | '13'
    | '14'
    | '15'
    | '16'
}

/** This should be put inside a overflow="hidden" container */
export const IllustrationContainer = ({
  illustrations,
  children,
  ...props
}: IllustrationContainerProps) => (
  <Container position={illustrations ? 'relative' : props.position} {...props}>
    {children}
    {illustrations && (
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        pointerEvents="none"
      >
        {illustrations === '01' && <SectionBackground01 />}
        {illustrations === '02' && <SectionBackground02 />}
        {illustrations === '04' && <SectionBackground04 />}
        {illustrations === '05' && <SectionBackground05 />}
        {illustrations === '06' && <SectionBackground06 />}
        {illustrations === '07' && <SectionBackground07 />}
        {illustrations === '08' && <SectionBackground08 />}
        {illustrations === '10' && <SectionBackground10 />}
        {illustrations === '13' && <SectionBackground13 />}
        {illustrations === '14' && <SectionBackground14 />}
        {illustrations === '15' && <SectionBackground15 />}
        {illustrations === '16' && <SectionBackground16 />}
      </Box>
    )}
  </Container>
)
