import React, { ReactElement } from 'react'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import { Svg } from '../../Svg'

export const PlusIcon = (props: BoxProps): ReactElement => (
  <Svg viewBox="0 0 22 20" {...props}>
    <g fill="none" stroke="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10C1 9.44772 1.44772 9 2 9H18C18.5523 9 19 9.44772 19 10C19 10.5523 18.5523 11 18 11H2C1.44772 11 1 10.5523 1 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18.991C9.44776 18.9862 9.00388 18.5347 9.0086 17.9825L9.14524 1.98304C9.14996 1.43078 9.60148 0.986902 10.1537 0.991619C10.706 0.996335 11.1499 1.44786 11.1452 2.00012L11.0085 17.9995C11.0038 18.5518 10.5523 18.9957 10 18.991Z"
        fill="currentColor"
      />
    </g>
  </Svg>
)
