import { APICustomer, Customer } from '@mindfulchefuk/query/Customer/interfaces'

export const transformCustomer = (data: APICustomer): Partial<Customer> => {
  const customer: Partial<Customer> = {
    customerId: data.id,
    firstName: data.first_name,
    lastName: data.last_name,
    email: data.email,
    createdAt: data.created_at,
    personalisationSurveyState:
      data.personalisation_survey_state ?? 'not_started',
  }

  if (data.default_delivery_address) {
    customer.address = {
      countryCode: data.default_delivery_address.country_code,
      deliveryInstructions: data.default_delivery_address.delivery_instructions,
      id: data.default_delivery_address.id,
      locality: data.default_delivery_address.locality,
      postcode: data.default_delivery_address.postcode,
      recipientPhone: data.default_delivery_address.recipient_phone,
      street1: data.default_delivery_address.street_1,
      street2: data.default_delivery_address.street_2,
      street3: data.default_delivery_address.street_3,
    }
  }

  if (data.contact_preferences) {
    customer.contactPreferences = {
      id: data.contact_preferences.id,
      emailMarketingOptIn: data.contact_preferences.email_marketing_opt_in,
      postMarketingOptIn: data.contact_preferences.post_marketing_opt_in,
      smsMarketingOptIn: data.contact_preferences.sms_marketing_opt_in,
      phoneMarketingOptIn: data.contact_preferences.phone_marketing_opt_in,
    }
  }

  return customer
}
