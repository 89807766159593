import { createReducer } from '@reduxjs/toolkit'

import {
  resetRecipes,
  setExcludedFoodGroups,
  setMealPlan,
  setRecipes,
  setRecipesLoading,
  sortRecipes,
} from '@mindfulchefuk/actions/recipesActions'

import { RecipesState } from '@mindfulchefuk/features/Recipes/interfaces'
import { sortRecipesBySortBy } from '@mindfulchefuk/features/Recipes/utils/sortRecipesBySortBy'

export const initialState: RecipesState = {
  allRecipes: [],
  selectedMealPlan: 'Balanced',
  excludedFoodGroups: [],
  sortedBy: 'customerFav',
  isPending: false,
  isFetched: false,
}

const recipesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setRecipesLoading, (state, action) => {
      const isPending = action.payload
      return {
        ...state,
        isPending,
        isFetched: !isPending,
      }
    })
    .addCase(setRecipes, (state, action) => {
      const allRecipes = action.payload

      return {
        ...state,
        allRecipes: sortRecipesBySortBy(allRecipes, state.sortedBy),
        sortedBy: 'customerFav',
        isPending: false,
        isFetched: true,
      }
    })
    .addCase(setMealPlan, (state, action) => ({
      ...state,
      selectedMealPlan: action.payload,
    }))
    .addCase(sortRecipes, (state, action) => ({
      ...state,
      sortedBy: action.payload,
      allRecipes: sortRecipesBySortBy([...state.allRecipes], action.payload),
    }))
    .addCase(resetRecipes, () => ({
      ...initialState,
    }))
    .addCase(setExcludedFoodGroups, (state, action) => ({
      ...state,
      excludedFoodGroups: action.payload,
    }))
    .addDefaultCase((state) => state)
})

export default recipesReducer
