import React, { ReactElement } from 'react'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'

//layout for recipes checkout March 2022
export const SectionBackground08 = (): ReactElement => (
  <>
    <Illustration
      type="cross-section-pomegranate"
      top="-30px"
      right={{
        base: '-180px',
        sm: '-175px',
        md: '-155px',
        lg: '-200px',
        xl: '-270px',
      }}
      transform="scale(0.81) rotate(-15deg)"
    />
    <Illustration
      type="cross-section-pumpkin-02"
      top={['780px', '600px']}
      left={{ base: '-62px', sm: '-55px', md: -40, lg: -80 }}
      transform="rotate(90deg)"
    />
    <Illustration
      type="cross-section-kale"
      top={{ base: '900px', md: 'calc(100% + -120px)' }}
      right={{ base: '-115px', md: '-95px', lg: '-100px', xl: '-160px' }}
    />
    <Illustration
      type="cross-section-courgette"
      display={{ base: 'block', md: 'none' }}
      bottom={-128}
      left={{ base: '-247px', sm: '-240px' }}
      transform="scale(0.32)"
    />
  </>
)
