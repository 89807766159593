import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import styled from '@emotion/styled'

import {
  Box,
  Flex,
  PandaButton,
  Stack,
  Text,
  theme,
} from '@mindfulchefuk/design-system'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'
import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import NextLink from 'next/link'

type TSpinner = {
  color: string
  size: number
}

const SPINNER_SCALE = 0.75
const StyledSpinner = styled.div<TSpinner>`
  display: inline-block;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.size * SPINNER_SCALE}px;
    height: ${(props) => props.size * SPINNER_SCALE}px;
    margin: ${(props) => props.size / 10}px;
    border: ${(props) => props.size / 10}px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }

  & div:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  & div:nth-of-type(2) {
    animation-delay: -0.3s;
  }

  & div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
`

export const Spinner = (props: Partial<TSpinner>): ReactElement => {
  const { color = theme.colors.aubergine, size = 64 } = props

  return (
    <StyledSpinner color={color as string} size={size}>
      <div />
      <div />
      <div />
      <div />
    </StyledSpinner>
  )
}

export const PageLoader = ({ children }: { children?: ReactNode }) => (
  <Flex
    width="100vw"
    height="100vh"
    direction="column"
    align="center"
    justify="center"
  >
    <Spinner />
    <MindfulChefLogo mt={20} width={176} height={36} color="aubergine" />
    {children}
  </Flex>
)

export const FixedLoader = ({ children }: { children?: ReactNode }) => {
  const [showLogout, setShowLogout] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShowLogout(true), 5000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <Box
      position="fixed"
      left={0}
      right={0}
      top={0}
      bottom={0}
      bg="white"
      z="modal"
    >
      <PageLoader>
        {children}

        {showLogout && (
          <Stack
            color="aubergine"
            textAlign="center"
            mt={24}
            align="center"
            position="relative"
            px={24}
          >
            <Text variant="body-lg" color="aubergine" mb={32}>
              This is taking a little longer than it should.
            </Text>
            <PandaButton
              size="small"
              variant="primary"
              onClick={() => window.location.reload()}
            >
              Refresh page
            </PandaButton>
            {/* Position absolute so no layout shift on the refresh button */}
            <Box position="absolute" bottom={-32}>
              <NextLink href={routesConfig.logout.pathname} passHref>
                <PandaButton variant="secondary" size="small">
                  Log out
                </PandaButton>
              </NextLink>
            </Box>
          </Stack>
        )}
      </PageLoader>
    </Box>
  )
}

const Loader = ({
  children,
  spinner,
  ...boxProps
}: BoxProps & { children?: ReactNode; spinner?: TSpinner }): ReactElement => (
  <Flex
    data-testid="loader-component"
    width="100%"
    height="100%"
    direction="column"
    align="center"
    justify="center"
    minHeight="inherit"
    {...boxProps}
  >
    <Spinner size={spinner?.size} color={spinner?.color} />
    {children}
  </Flex>
)

export default Loader

/** Adds a loader over content, requires position: relative or similar on a parent component */
export const LoaderOverContent = (): ReactElement => (
  <Box
    position="absolute"
    bg="white.50"
    width="100%"
    height="100%"
    z="loaderOverlay"
    data-testid="loader-overlay"
  >
    <Loader />
  </Box>
)
