import React, { ReactChild, ReactElement } from 'react'
import styled from '@emotion/styled'
import Link, { LinkProps } from 'next/link'
import { motion, Variants } from 'motion/react'
import { Text } from '@mindfulchefuk/design-system/Text/Text'
import { Icon } from '@mindfulchefuk/design-system/Icon/Icon'

const MotionFlex = styled(motion.a)`
  align-items: center;
  color: 'aubergine';
  display: flex;
`
const MotionIconWrapper = styled(motion.span)`
  display: inline-flex;
`

const styledArrowContainerVariants: Variants = {
  hover: {
    x: [0, -10, 0, -10, 0],
    transition: { duration: 0.6, ease: 'easeOut' },
  },
}

type TBackLink = {
  children?: ReactChild | ReactChild[]
  href: LinkProps['href']
  'data-testid'?: string
  onClick?: () => void
}

export const BackLink = ({
  children,
  href,
  'data-testid': testId,
  onClick,
}: TBackLink): ReactElement => (
  <Link href={href} passHref onClick={onClick} legacyBehavior={false}>
    <MotionFlex initial={false} whileHover="hover">
      <MotionIconWrapper variants={styledArrowContainerVariants}>
        <Icon type="arrow-left" mr={10} size={20} />
      </MotionIconWrapper>

      <Text
        as="span"
        variant={{ base: 'body-sm-500-caps', md: 'body-lg-500-caps' }}
        color="aubergine"
        data-testid={testId}
      >
        {children}
      </Text>
    </MotionFlex>
  </Link>
)
